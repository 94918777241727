module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/site/node_modules/@cinemataztic/gatsby-theme-cinemataztic-cms/src/layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"CMS | CinemaTaztic","manualInit":true,"enableIdentityWidget":true,"modulePath":"/opt/build/repo/site/node_modules/@cinemataztic/gatsby-theme-cinemataztic-cms/src/cms/config.js"},
    }]
